<template>
  <div>
    <a
      class="text"
      data-cy="link-forgot-pwd"
      @click="showForm">
      {{ $t('forgotPassword') }}
    </a>

    <CyModal
      v-if="formVisibility"
      :header-title="$t('forgotPassword')"
      :loading="loading"
      :action-btn-text="$t('formsBtnReset')"
      :action-btn-func="submit"
      :action-btn-disabled="$v.email.$invalid"
      :cancel-btn-func="close"
      small
      modal-type="update">
      <p>
        {{ $t('formExplicativeText1') }}<br>
        {{ $t('formExplicativeText2') }}
      </p>

      <CyAlert
        theme="error"
        :content="resetPasswordErrors"/>

      <form
        @submit.prevent="submit">
        <v-text-field
          v-model="$v.email.$model"
          :label="$t('forms.fieldEmail')"
          :error-messages="emailErrors"
          required
          class="required-field"/>
      </form>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { gtmLoginEvents } from '@/utils/helpers/analytics' // eslint-disable-line no-unused-vars
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsForgotPassword',
  validations: {
    email: {
      required,
      email: (val) => email(val.toLowerCase()),
    },
  },
  data: () => ({
    email: '',
    loading: false,
    formVisibility: false,
  }),
  computed: {
    ...mapState('user', {
      resetPasswordErrors: (state) => state.errors.resetPassword,
    }),
    emailErrors () {
      const errors = []
      const { $dirty, email, required } = this.$v.email
      if (!$dirty) return errors
      if (!email) errors.push(this.$t('forms.fieldNotEmail'))
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  methods: {
    ...mapActions('user', [
      'RESET_PASSWORD',
    ]),
    async submit () {
      this.loading = true
      this.$v.$touch()

      await this.RESET_PASSWORD({ email: this.email.toLowerCase() })
      if (_.isEmpty(this.resetPasswordErrors)) this.close()

      this.loading = false
    },
    close () {
      this.$resetData()
      this.$v.$reset()
      this.errors = null
      this.$toggle.formVisibility(false)
    },
    showForm () {
      this.$toggle.formVisibility(true)
      this.$gtm.trackEvent(gtmLoginEvents.forgotPassword)
    },
  },
  i18n: {
    messages: {
      en: {
        forgotPassword: 'Forgot Password?',
        formExplicativeText1: 'To reset your password, enter your primary email here.',
        formExplicativeText2: 'If an account is associated to it, a reset link will be sent to that email.',
        formsBtnReset: 'Reset Password',
      },
      es: {
        forgotPassword: '¿Ha olvidado su contraseña?',
        formExplicativeText1: 'Para cambiar su contraseña introduzca su dirección de correo principal.',
        formExplicativeText2: 'Si existe una cuenta asociada, un enlace de cambio de contraseña será enviado por correo.',
        formsBtnReset: 'Cambiar contraseña',
      },
      fr: {
        forgotPassword: 'Mot de passe oublié?',
        formExplicativeText1: 'Pour réinitialiser votre mot de passe, veuillez entrer votre courriel principal.',
        formExplicativeText2: 'Si un compte lui est associé, un lien de réinitialisation sera envoyé à cet email.',
        formsBtnReset: 'Réinitialiser le mot de passe',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.text {
  color: cy-get-color("secondary");
}
</style>
