<template>
  <v-form
    class="public-page-form justify-center d-flex flex"
    @submit.prevent="formLogin">
    <v-card :flat="hasTwoPaneLayout">
      <v-card-text>
        <v-row>
          <v-col class="text-center">
            <span
              v-if="awsMarketplaceToken"
              class="public-page-form__step">
              {{ $t('forms.step') }} 1 / 2
            </span>
            <h2
              v-if="hasTwoPaneLayout"
              class="public-page-form__title mb-6">
              {{ $t('login') }}
            </h2>
            <router-link
              v-else
              :to="{ name: 'login' }">
              <CyAppPublicLogo class="public-page-form__logo"/>
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="!_.isEmpty(errors)">
          <v-col class="mx-6 px-3 pt-0">
            <CyAlert
              theme="error"
              :content="errors"/>
          </v-col>
        </v-row>
        <v-row v-if="showLoginMessage">
          <v-col class="mx-6 px-3 pt-0">
            <CyAlert
              data-cy="login-message"
              :theme="_.get(loginMessage, 'theme')"
              :title="_.get(loginMessage, 'title')"
              :content="_.get(loginMessage, 'content')"/>
          </v-col>
        </v-row>
        <v-row class="mx-6 mt-4">
          <v-col>
            <v-text-field
              v-model="login.email"
              v-fix-autofill-rendering
              data-cy="email-input"
              :label="$t('forms.fieldEmail')"
              :error-messages="emailErrors"
              append-icon="email"
              autocomplete="email"
              @focus="resetEmailInput"
              @blur="$v.login.email.$touch()"/>
          </v-col>
        </v-row>
        <v-row class="mx-6">
          <v-col>
            <v-text-field
              v-model="login.password"
              v-fix-autofill-rendering
              data-cy="password-input"
              :label="$t('forms.fieldPassword')"
              :error-messages="passwordErrors"
              :append-icon="show.password ? 'visibility_off' : 'visibility'"
              :hide-details="!hasPasswordErrors"
              :type="show.password ? 'text' : 'password'"
              min="8"
              autocomplete="current-password"
              @click:append="$toggle.show.password"
              @focus="resetPasswordInput"
              @blur="$v.login.password.$touch()"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="!hasPasswordErrors"
            class="col--forgot-password">
            <CyFormsForgotPassword/>
          </v-col>
        </v-row>
        <v-row :class="hasOAuthProviders ? 'ma-6 mb-8' : 'ma-6 mb-10'">
          <v-col class="text-center pb-2">
            <CyButton
              data-cy="submit-btn"
              :loading="isLoading"
              :disabled="isLoading || $v.login.$invalid"
              type="submit">
              {{ $t('login') }}
            </CyButton>
          </v-col>
        </v-row>
        <v-row
          v-if="hasOAuthProviders || hasSaml2Providers"
          class="d-flex align-center px-9">
          <v-divider class="darkgrey"/>
          <span class="login-with">{{ $t('loginWith') }}</span>
          <v-divider class="darkgrey"/>
        </v-row>
        <v-row
          v-if="hasSSOProviders"
          class="mx-6 mt-6 mb-8">
          <v-col class="d-flex align-center justify-center space-x-4">
            <CySSOButton
              v-for="provider of socialProviders"
              :key="provider"
              :size="28"
              :loading="oidcLoading[provider]"
              :sso-provider="provider"
              @start-sso-flow="startOAuthFlow"/>
            <CySSOButton
              v-for="{ provider, sso_url } of appConfig.authentication.saml2"
              :key="provider"
              :size="36"
              sso-provider="saml"
              :sso-url="sso_url"
              @start-sso-flow="navigateToSaml2Provider"/>
          </v-col>
        </v-row>
        <div
          v-if="false"
          class="text-center">
          {{ $t('noAccount') }}
          <router-link
            class="cy-link"
            :to="{ ...$route, name: 'signUp' }"
            data-cy="link-signup"
            @click.native="$gtm.trackEvent($static.gtmLoginEvents.signupHyperlink)">
            {{ $t('signUp') }}
          </router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import CyFormsForgotPassword from '@/components/forms/forgot-password.vue'
import CySSOButton from '@/components/sso-button.vue'
import oidc from '@/mixins/oidc'
import { gtmLoginEvents } from '@/utils/helpers/analytics'
import { required, email, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageLogIn',
  components: {
    CyFormsForgotPassword,
    CySSOButton,
  },
  mixins: [oidc],
  props: {
    orgCanonicalToLogIn: {
      type: String,
      default: null,
    },
  },
  validations: {
    login: {
      email: {
        required,
        email: (val) => email(val.toLowerCase()),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  data: () => ({
    isLoading: false,
    login: {
      email: '',
      password: '',
    },
    show: {
      password: false,
    },
  }),
  computed: {
    ...mapState('auth', {
      awsMarketplaceToken: (state) => state.awsMarketplaceToken,
      loginErrors: (state) => state.errors.login,
      jwt: (state) => state.jwt,
      ssoToken: (state) => state.sso.token,
      verifyEmailErrors: (state) => state.errors.verifyEmail,
    }),
    ...mapGetters([
      'hasOrgs',
      'socialProviders',
    ]),
    $static: () => ({
      gtmLoginEvents,
    }),
    emailErrors () {
      const errors = []
      const { $dirty, email, required } = this.$v.login.email
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!email) errors.push(this.$t('forms.fieldNotEmail'))
      return errors
    },
    passwordErrors () {
      const errors = []
      const { $dirty, required, minLength } = this.$v.login.password
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('forms.fieldMinLength', { number: 8 }))
      return errors
    },
    hasPasswordErrors () {
      return !_.isEmpty(this.passwordErrors)
    },
    nonMFAErrors () {
      return _.reject(this.loginErrors, ['code', 'RequireUserMFA'])
    },
    errors () {
      return [
        ...this.nonMFAErrors,
        ...this.oidcErrors,
        ...this.ssoErrors,
        ...this.verifyEmailErrors,
      ]
    },
    hasNewVersion () {
      const storedVersion = localStorage.getItem(LSK.APP_VERSION)
      return !!storedVersion && !_.isEqual(process.env.VUE_APP_VERSION, storedVersion)
    },
    hasTwoPaneLayout () {
      return this.$route.meta?.layout?.isTwoPane ?? false
    },
    showLoginMessage () {
      return !_.isEmpty(this.loginMessage)
    },
    loginMessage () {
      const { $route: { query: { action, email } }, errors, loginErrors, verifyEmailErrors } = this
      const theme = 'success'

      if (this.isLoading) return null

      const actionMessage = {
        accountDeleted: _.isEmpty(errors)
          ? { theme, content: this.$t('accountDeleted', { email }) }
          : null,
        emailConfirmed: _.isEmpty([loginErrors, verifyEmailErrors].flat())
          ? { theme, content: this.$t('emailConfirmed') }
          : null,
        passwordReset: _.isEmpty(loginErrors)
          ? { theme, content: this.$t('passwordResetDone') }
          : null,
      }?.[action]

      const newVersionMessage = this.hasNewVersion
        ? { theme: 'info', content: this.$t('newVersion', { number: process.env.VUE_APP_VERSION }) }
        : null

      return actionMessage || newVersionMessage
    },
  },
  mounted () {
    this.getAwsMarketplaceToken()
    this.CLEAR_AUTH_ERRORS('login')
  },
  destroyed () {
    this.CLEAR_AUTH_ERRORS('verifyEmail')
  },
  methods: {
    ...mapActions('auth', [
      'LOGIN',
    ]),
    ...mapMutations('auth', [
      'CLEAR_CREDENTIALS',
      'CLEAR_AUTH_ERRORS',
    ]),
    ...mapMutations('user', [
      'CLEAR_PROFILE',
    ]),
    ...mapMutations('organization', [
      'SET_ORGANIZATION',
    ]),
    async formLogin () {
      this.isLoading = true
      this.$v.$touch()
      this.CLEAR_AUTH_ERRORS('verifyEmail')

      const { email, password } = this.login
      try {
        await this.LOGIN({ email: email.toLowerCase(), password, orgCanonical: this.orgCanonicalToLogIn })
      } catch (error) {
        if (!_.$isEmpty(this.loginErrors) || !(this.jwt || this.ssoToken)) {
          this.CLEAR_CREDENTIALS()
          this.CLEAR_PROFILE()

          const hasRequireUserMFAError = _.some(this.loginErrors, { code: 'RequireUserMFA' })
          if (hasRequireUserMFAError) {
            this.$router.push({ name: 'MFALoginLinkNotice', params: { email: email.toLowerCase() } }).catch(() => { /* silenced */ })
          }
          const isUnverifiedError = _.some(this.loginErrors, { code: 'UnauthenticatedEmailNotVerified' })
          if (isUnverifiedError) this.$router.push({ name: 'accountVerification', query: { action: 'verifyFirst' } })
          this.isLoading = false
          return
        }
      }

      this.$gtm.trackEvent(this.$static.gtmLoginEvents.login)

      await this.handleRedirectAfterLogIn()
      this.isLoading = false
    },
    async handleRedirectAfterLogIn () {
      if (this.hasOrgs) {
        await this.redirectToDefaultRoute()
        return
      }

      this.awsMarketplaceToken
        ? this.redirectToMarketplaceNewOrg()
        : this.redirectToOrgsList()
    },
    redirectToOrgsList () {
      this.SET_ORGANIZATION()
      this.$router.push({ name: 'organizations' })
    },
    redirectToMarketplaceNewOrg () {
      this.$router.push({ name: 'marketplaceNewOrganization' })
    },
    resetEmailInput () {
      this.$v.login.email.$reset()
      this.$gtm.trackEvent(this.$static.gtmLoginEvents.email)
    },
    resetPasswordInput () {
      this.$v.login.password.$reset()
      this.$gtm.trackEvent(this.$static.gtmLoginEvents.password)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.login',
        accountDeleted: 'Your account for <b>{email}</b> was successfully deleted',
        emailConfirmed: 'You have successfully verified your account.',
        login: 'Log in',
        loginWith: 'Or login with',
        newVersion: '<b>The platform has been updated to version {number}</b>. Please login again to access the latest features.',
        noAccount: `Don't have an account?`,
        passwordResetDone: 'Your password has been reset',
        signUp: 'Sign up',
      },
      es: {
        title: '@:routes.login',
        accountDeleted: 'Su cuenta para <b>{email}</b> se eliminó correctamente',
        emailConfirmed: 'Has verificado con éxito tu cuenta.',
        login: 'Iniciar session',
        loginWith: 'O inicie sesión con',
        newVersion: '<b>La plataforma ha sido actualizada a la versión {number}</b>. Inicie sesión nuevamente para acceder a las funciones más recientes.',
        noAccount: '¿No tiene una cuenta ?',
        passwordResetDone: 'La contraseña ha sido cambiada',
        signUp: 'Inscribirse',
      },
      fr: {
        title: '@:routes.login',
        accountDeleted: 'Votre compte pour <b>{email}</b> a bien été supprimé',
        emailConfirmed: 'Vous avez validé votre compte avec succès.',
        login: 'Connection',
        loginWith: 'Ou connectez-vous avec',
        newVersion: '<b>La plateforme a été mise à jour vers la version {number}</b>. Veuillez vous reconnecter pour accéder aux dernières fonctionnalités.',
        noAccount: `Vous n'avez pas de compte?`,
        passwordResetDone: 'Le mot de passe a été réinitialisé',
        signUp: 'Inscrivez-vous',

      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill + label {
  transform: translateY(-18px) scale(0.75);
}

.v-card .col--forgot-password {
  position: relative;
  top: -5px;
  margin-right: 32px;
  padding: 0;
  text-align: right;
}
</style>
